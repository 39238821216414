<template>
  <div>

    <div class="container">
      <div class="row ">
        <div class="col-12 align-content-center justify-content-center">
          <h1>Проект: {{project_name}}</h1>
        </div>
      </div>
    </div>

    <div class="container">


      <h2> Документы </h2>

      <div class="row" v-for="(document,ix) in documents">
        <div class="col">{{ix+1}}. <a class="text-primary class ml-2" :href="getDocumentURL(document.document_hash)">{{document.document_name}}</a></div>
        <div class="col ml-4 text-primary">{{document.inserted}}</div>
      </div>

    </div>




    </div>




</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon } from "vue-feather-icons";

export default {
  components: {
    MessageCircleIcon, BookIcon, DownloadIcon,
  },
  name: "WhiperXPrject",
  data() {
    return {
      input_api: {project_hash:'',user_id:0},
      documents: [],
      project_name:'',

    }
  },


  mounted() {
    let hash = this.$route.params.hash
    this.getDocumentsFromProject(hash);
  },
  methods: {

    getDocumentURL(hash) {
      return this.$hostnamefront+'/whisperx/document/'+hash;
    },

    getDocumentsFromProject(hash) {

      this.input_api.project_hash = hash;
      this.input_api.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/whisperx/get-documents-from-project',
        data: this.input_api,
      }).then((res) => {
        this.documents = res.data.results;
        this.project_name = res.data.project_name;
      });

    },

  }
}
</script>

<style scoped></style>